import _objectSpread from "C:/Users/10666/Desktop/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top";
import packA from "./components/pack-a";
import packB from "./components/pack-b";
import packC from "./components/pack-c"; // import packD from "./components/pack-d";

import packF from "./components/pack-f";
import packG from "./components/pack-g";
import packH from "./components/pack-h";
import commonFooter1 from "@/components/common-footer1";
import CommonRecommend from "@/components/common-recommend";
import { mapMutations } from "vuex";
export default {
  metaInfo: {
    title: "智能包装解决方案",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "RFID智能包装,防伪溯源,防伪溯源整体方案,常达,智能包装"
    }, {
      name: "description",
      content: "智能包装解决方案是整合包装、智能手机应用、NFC & RFID 芯片技术和数据来连接企业和客户的需求，提供智能包装的一站式的解决方案。智能包装套装 3.0版本包括智能包装、webAPP、Dashboard、PDA。"
    }]
  },
  components: {
    CommonRecommend: CommonRecommend,
    top: top,
    packA: packA,
    packB: packB,
    packC: packC,
    // packD,
    packF: packF,
    packG: packG,
    packH: packH,
    commonFooter1: commonFooter1
  },
  data: function data() {
    return {
      swiperTitle1: "移动网站",
      swiperInfo1: "通过将智能包装应用在产品上，消费者非接触式触碰包装的NFC 智能标签，就可访问特定的网站或平台",
      swiperList1: [{
        t: "即时链接",
        c: "快速访问您的品牌官方网站﹐让消费者更全面了解品牌和产品信息",
        icon: require("@/assets/pack/moveweb/icon1.png"),
        img: require("@/assets/pack/moveweb/1.png")
      }, {
        t: "简易开发",
        c: "移动网站易于开发﹐适合新产品进行市场测试",
        icon: require("@/assets/pack/moveweb/icon2.png"),
        img: require("@/assets/pack/moveweb/1.png")
      }, {
        t: "数据采集",
        c: "数据可通过网站收集﹐如地理位置数据和部分用户个人信息",
        icon: require("@/assets/pack/moveweb/icon3.png"),
        img: require("@/assets/pack/moveweb/1.png")
      }, {
        t: "连接服务",
        c: "移动网站非常灵活，它可以连接到您现有的服务，如电子商务平台",
        icon: require("@/assets/pack/moveweb/icon4.png"),
        img: require("@/assets/pack/moveweb/1.png")
      }],
      swiperTitle2: "Dashboard",
      swiperInfo2: "品牌方在应用系统的后台，可实时查看汇总到后台的多种信息情况，并能根据信息的分析，辅助管理层决策",
      swiperList2: [{
        t: "用户信息",
        c: "允许用户更容易地创建自己的个人帐户，并长期保留在手机上",
        icon: require("@/assets/pack/APP/icon1.png"),
        img: require("@/assets/pack/APP/1.png")
      }, {
        t: "用户反馈",
        c: "消费者可及时反馈产品信息、使用感受、商品建议等等",
        icon: require("@/assets/pack/APP/icon2.png"),
        img: require("@/assets/pack/APP/1.png")
      }, {
        t: "产品保修",
        c: "如有需要，可以方便、快捷地申请产品保修，并可跟踪维修情况",
        icon: require("@/assets/pack/APP/icon3.png"),
        img: require("@/assets/pack/APP/1.png")
      }, {
        t: "产品市场",
        c: "通过消费者的行为，了解产品在市场上的普及情况和互动情况",
        icon: require("@/assets/pack/APP/icon4.png"),
        img: require("@/assets/pack/APP/1.png")
      }],
      a1: {
        t: "智能包装解决方案",
        c: "将包装、智能手机、NFC & RFID技术整合，通过数据链接企业和终端消费者，提供一站式智能包装解决方案。",
        img: require("@/assets/pack/a/1.png")
      },
      a2: {
        t: "智能包装1.0版本应用",
        c: "使用NFC技术，通过智能手机的触碰，即可查看包装情况，完成基本数据收集，并可将包装与商业网站链接，同时进行广告推广"
      },
      a2_1: {
        t: "智能包装2.0版本应用",
        c: "基于1.0版本，新增了用户反馈信息收集、产品保修情况以及产品市场情况等信息汇总。"
      },
      a2_2: {
        t: "智能包装3.0版本应用",
        c: "增加了RFID技术，拓展了与VR、WMS、AR的功能和数据对接，并应用最新的区块链技术，辅助实现产品唯一的防伪溯源信息"
      },
      a3: {
        t: "<text style='font-size:.6rem;margin-right: .2rem;color:#5EC7B9;'></text> 数据采集",
        c: "智能包装，是用实体商品收集消费数据的最实用方式",
        img: require("@/assets/pack/a/3.png")
      },
      a4: {
        t: "<text style='font-size:.6rem;margin-right: .2rem;color:#5EC7B9;'></text>  数据分析",
        c: "在收集了数百万个消费者和产品之间创建的数据后，就可以开始了解和预测他们的行为习惯。",
        img: require("@/assets/pack/a/4.png")
      },
      c1: {
        t: "可以收集什么样的数据？",
        c: "智能包装上可以收集的数据超乎想像，消费者和包装之间的每一次交互都可以提供大量的数据。",
        image: require("@/assets/pack/c/5.png"),
        list: [{
          icon: require("@/assets/pack/c/1.png"),
          t: "消费者数据",
          c: "了解您的消费者。比如消费者性别、年龄段、购物地点偏好等特征化的数据"
        }, {
          icon: require("@/assets/pack/c/2.png"),
          t: "营销数据",
          c: "在消费者数据的基础上，通过特征化的分类，更好的进行营销，并收集营销数据，调整营销策略"
        }, {
          icon: require("@/assets/pack/c/3.png"),
          t: "供应链数据",
          c: "根据智能包装上的芯片，自动采集到工厂生产时的数据，以及产品仓储数据，更好掌握供应链相关的生产和仓储数据"
        }, {
          icon: require("@/assets/pack/c/4.png"),
          t: "产品数据",
          c: "采集产品消费数据，如产品购买频率、产品购买地点、产品陈列的地点分布特点等等"
        }]
      },
      c2: {
        t: "您可以根据上述数据找到哪些结果？",
        c: "",
        image: require("@/assets/pack/c/10.png"),
        reverse: true,
        list: [{
          icon: require("@/assets/pack/c/6.png"),
          t: "消费者行为",
          c: "通过观察客户如何互动、购买和使用您的产品来了解您的消费者。如客户多久购买一次、在哪里、通常什么时候购买、对你的广告有兴趣吗等。"
        }, {
          icon: require("@/assets/pack/c/7.png"),
          t: "人口统计",
          c: "找出您的客户群体的人口统计数据特征﹐量身定制适合他们的解决方案。如客户的平均年龄、男性和女性客户的比例、职业、月收入等。"
        }, {
          icon: require("@/assets/pack/c/8.png"),
          t: "营销情报",
          c: "通过大数据分析及了解您的营销活动﹐通过数据驱动进行决策。如 客户平均价值、 转化率、 自然流量、 客户留存率是多少等。"
        }, {
          icon: require("@/assets/pack/c/9.png"),
          t: "生产管理",
          c: "管理和跟进您的生产流程和进度﹐根据市场需要及时调整生产计划。如每个单位的产能、生产线产能是否被充分利用、根据客户的喜好设计您的生产计划等。\n"
        }]
      },
      roundSwiperList: [// {
      //   t: "智能吊牌",
      //   t1: "RFID & NFC 吊牌",
      //   c: "利用云平台将消费者、企业、流通三者连接起来，从而能提供全面的O2O营销、防伪追溯、仓储物流等解决方案的一种技术",
      //   img: require("@/assets/pack/swiper-r/1.png"),
      //   icon: require("@/assets/pack/icon1.png"),
      // },
      {
        t: "智能标签",
        t1: "RFID & NFC 智能标签",
        c: "通过吊牌、标签等形式，实现智能包装的应用",
        img: require("@/assets/pack/swiper-r/2.png"),
        icon: require("@/assets/pack/icon2.png")
      }, {
        t: "智能卡",
        t1: "RFID & NFC 智能卡",
        c: "在合适的场景，使用卡片的形式，达到智能包装的应用",
        img: require("@/assets/pack/swiper-r/3.png"),
        icon: require("@/assets/pack/icon3.png")
      }, {
        t: "智能贴纸",
        t1: "RFID & NFC 贴纸",
        c: "结合现有的包装，在不更改包装的基础上，使用贴纸粘贴在包装上",
        img: require("@/assets/pack/swiper-r/4.png"),
        icon: require("@/assets/pack/icon4.png")
      }, {
        t: "智能宣传",
        t1: "RFID & NFC 智能宣传卡单张",
        c: "在传统宣传卡的基础上，结合RFID & NFC技术，消费者可与宣传资料互动",
        img: require("@/assets/pack/swiper-r/5.png"),
        icon: require("@/assets/pack/icon5.png")
      }],
      swiperList: [{
        t: "目标市场营销",
        c: "工作人员对所有指定资产配上一个RFID电子标签，同时将资产信息写入RFID电子标签，并将信息通过相应的软件存入服务器以作识别之用。",
        icon: require("@/assets/pack/swiper/1.png"),
        icon2: require("@/assets/pack/swiper/1c.png")
      }, {
        t: "防伪",
        c: "通过交叉认证服务器与RFID & NFC 标签芯片技术来辨别产品真伪，给予消费者更多的信心。",
        icon: require("@/assets/pack/swiper/2.png"),
        icon2: require("@/assets/pack/swiper/2c.png")
      }, {
        t: "企业自动化",
        c: "把特定情况、推广和促销等活动变为自动化运营，更精确和省时。",
        icon: require("@/assets/pack/swiper/3.png"),
        icon2: require("@/assets/pack/swiper/3c.png")
      }, {
        t: "大数据分析",
        c: "通过商业智能收集和分析产品、客户、业务和制造的数据以获得更准确的预测。",
        icon: require("@/assets/pack/swiper/4.png"),
        icon2: require("@/assets/pack/swiper/4c.png")
      }, {
        t: "多种服务",
        c: "各样服务和技术都可应用于智能包装平台，没有限制。",
        icon: require("@/assets/pack/swiper/5.png"),
        icon2: require("@/assets/pack/swiper/5c.png")
      }, {
        t: "直接与消费者沟通",
        c: "跳过中介渠道，直接与您的消费者沟通，并在智能包装平台上了解他们的需求和意见。",
        icon: require("@/assets/pack/swiper/6.png"),
        icon2: require("@/assets/pack/swiper/6c.png")
      }],
      recommend: {
        title: "智能包装产品推荐",
        list: [{
          image: require("@/assets/pack/recommend/1.png"),
          to: "/product?name=NFC标签&current=2"
        }, {
          image: require("@/assets/pack/recommend/2.png"),
          to: "/product?name=四通道读写器&current=4"
        }, {
          image: require("@/assets/pack/recommend/3.png"),
          to: "/product?name=NFC标签"
        }, {
          image: require("@/assets/pack/recommend/4.png"),
          to: "/product?name=NFC标签&current=1"
        }, {
          image: require("@/assets/pack/recommend/5.png"),
          to: "/product?name=手持机"
        }]
      }
    };
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  mounted: function mounted() {
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};